!function ($, window) {
  "use strict";

  // Vars
  var $doc = $(document), $win = $(window), SITE = SITE || {};

  SITE = {
    // Init - Constructeur
    init: function () {
      var obj, self = this;
      for (obj in self) if (self.hasOwnProperty(obj)) {
        var _method = self[obj];
        void 0 !== _method.selector && void 0 !== _method.init && $(_method.selector).length > 0 && _method.init();
      }
    },

    // Slider HP
    hpSlider: {
      selector: "#slider .carousel",
      init: function () {
        var base = this, container = $(base.selector);

        container.owlCarousel({
          items: 1,
          loop: true,
          dots: true,
          autoplay: true,
          animateOut: 'fadeOut'
        });
      }
    },

    //nav
    Nav: {
      selector: ".trigger-menu",
      init: function () {
        var base = this, container = $(base.selector);

        container.on('click', function (e) {
          e.preventDefault();
          container.toggleClass('open');
          $('#sidebar').toggleClass('menu-open');
          $('body').toggleClass('menu-open');
        });

        $doc.on('click', function (e) {
          var $clicked = $(e.target);
          if (!($clicked.parents().hasClass("main-nav") || $clicked.hasClass("main-nav") || $clicked.parents().hasClass("trigger-menu") || $clicked.hasClass("trigger-menu"))) {
            container.removeClass('open');
            $('#sidebar').removeClass('menu-open');
            $('body').removeClass('menu-open');
          }
        });
      }
    },

    NavSubmenu: {
      selector: "#navigation .nav > li > a",
      init: function () {
        var base = this, container = $(base.selector);
        container.each(function () {
          if ($(document).width() < 1200) {
            if ($(this).parent("li").find("ul").length > 0) {
              $(this).after("<span class='expand'><i class='fa fa-plus' aria-hidden='true'></i></span>");
            }
          }
        });
        $("#navigation .nav > li").click(function (e) {
          e.stopPropagation();
        });
        $("#navigation .nav > li > .expand").click(function (e) {
          $(this).next("ul").toggleClass("open");
        });
      }
    },

    //scroll down
    scrollDown: {
      selector: ".scrollDown a",
      init: function () {
        var base = this, container = $(base.selector);

        container.on('click', function (e) {
          e.preventDefault();
          var targ = $(this).attr("href");
          $("html, body").animate({ scrollTop: $("." + targ + "").offset().top }, 1000);
        });
      }
    },

    //scroll down
    contactScrollDown: {
      selector: "html",
      init: function () {
        var base = this, container = $(base.selector);

        var path = window.location.pathname.replace("/", "");
        if (path == "contact") {
          $("html, body").animate({ scrollTop: $("#content").offset().top }, 1000);
        }
      }
    },
    //scroll down
    contentpageScrollDown: {
      selector: ".node-type-page",
      init: function () {
        var base = this, container = $(base.selector);

        if ($('body').hasClass("node-type-page")) {
          $("html, body").animate({ scrollTop: $("#content").offset().top }, 1000);
        }
      }
    },

    //realisation filter
    filterRealisation: {
      selector: "#realisations",
      init: function () {
        var base = this, container = $(base.selector);

        var getParameter = function getParameter(url, argument) {
          var splitUrl = url.split('?'),
            parameters = splitUrl[1].split('&'),
            parameter,
            i;

          for (i = 0; i < parameters.length; i++) {
            parameter = parameters[i].split('=');

            if (parameter[0] === argument) {
              return parameter[1];
            }
          }
        };

        $('#realisations .filter .form-item a').on('click', function (event) {
          event.preventDefault();

          var link = $(this),
            targetAttr = $(this).attr("href"),
            target = getParameter(targetAttr, 'c');

          $('#realisations .filter .form-item a').removeClass('active');
          link.addClass('active');

          $('#realisations .realisation').show();
          $('#realisations .realisation').removeClass('disabled');

          $('#realisations .realisation').each(function () {
            if (target != 'All') {
              if ($(this).data('cat') != target) {
                $(this).addClass('disabled');
                $(this).hide();
              }
            } else {
              $('#realisations .realisation').removeClass('disabled');
              $('#realisations .realisation').show();
            }
          });
        });
      }
    },

    // Shuffle realisation
    shuffleReal: {
      selector: "#realisations .view-content",
      init: function () {
        var base = this, container = $(base.selector);

        var reals = container.find(".realisation");
        while (reals.length) {
          container.append(reals.splice(Math.floor(Math.random() * reals.length), 1)[0]);
        }
      }
    },


    //nav
    navFixed: {
      selector: "#header",
      init: function () {
        var base = this, container = $(base.selector), winHeight = $win.height();

        if ($win.scrollTop() > 0) {
          container.addClass("fixed");
        } else {
          container.removeClass("fixed");
        }
      }
    },
    //nav
    anchorScroll: {
      selector: ".scroll-link",
      init: function () {
        var base = this, container = $(base.selector);
        $(document).on('click', '.scroll-link', function (event) {
          event.preventDefault();
          var topPos = $($.attr(this, 'href')).offset().top;
          $('html, body').animate({
            scrollTop: topPos - 70
          }, 500);
        });
      }
    },
    //nav
    reaFiltersMobile: {
      selector: "#realisations .view-filters ul li a",
      init: function () {
        var base = this, container = $(base.selector);
        if ($(document).width() <= 768 && $('.filters-select').length == 0) {
          $(".view-filters ul").before("<select class='filters-select'></select>");
          $(".filters-select ").append('<option value="/nos-realisations">Toutes nos réalisations</option>');
          container.each(function () {
            var link = $(this).attr("href");
            var name = $(this).text();
            $(".filters-select ").append("<option value='" + link + "'>" + name + "</option>");
          });
          $(".filters-select").change(function () {
            var toGo = $(this).find("option:selected").val();
            window.location = toGo;
          });
          console.log(window.location.pathname);
          $(".filters-select").find('option').each(function () {
            if ($(this).val() == window.location.pathname) {
              $(this).attr("selected", "selected");
            }
          });
        }
      }
    },
    //nav
    realisationLimits: {
      selector: ".realisations-list .realisation",
      init: function () {
        var base = this, container = $(base.selector);
        $(".realisations .container").append('<div style="text-align:center;"><a href="" class="btn btn-primary more-rea">Voir plus de réalisations</a></div>');
        $('.more-rea').hide();
        container.each(function (i) {
          if (i > 11) {
            $(this).hide();
            $(".more-rea").show();
          }
        });
        $(".more-rea").click(function (e) {
          e.preventDefault();
          container.show();
          $(".more-rea").hide();
        });
      }
    },

    //filter realisations
    realisationFilter: {
      selector: ".trigger-filter",
      init: function () {
        var base = this, container = $(base.selector);

        container.on('click', function (e) {
          e.preventDefault();
          $(this).toggleClass('open');
          $("#realisations .view-filters").slideToggle();
        });

        $doc.on('click', function (e) {
          if (container.hasClass('open')) {
            var $clicked = $(e.target);
            if (!($clicked.parents().hasClass("trigger-filter") || $clicked.hasClass("trigger-filter"))) {
              $("#realisations .view-filters").slideUp();
              container.removeClass('open');
            }
            if ($clicked.parent().hasClass("view-filters")) {
              $("html, body").animate({ scrollTop: 0 });
            }
          }
        });
      }
    },

    //colorbox
    colorbox: {
      selector: "#realisations .realisation a",
      init: function () {
        var base = this, container = $(base.selector);
        container.colorbox({
          maxWidth: '95%',
          maxHeight: '95%',
          rel: function () {
            var group = $(this).parents(".realisation").data('cat');
            return "cat_" + group;
          }
        });
      }
    },

    // Resize
  }, $win.resize(function () {
    SITE.navFixed.init();
    SITE.NavSubmenu.init();
    SITE.reaFiltersMobile.init();

    var $docWidth = $doc.width();
    if ($docWidth > 767) {
      $('.trigger-filter').removeClass('open');
      $("#realisations .bef-select-as-links").removeAttr('style');
    }

    // Scroll
  }), $win.scroll(function () {
    SITE.navFixed.init();
    // Ready
  }), $doc.ready(function () {
    SITE.init();
  });
}(jQuery, this);
